import { types } from "@gadgetinc/mobx-quick-tree";
import type { Branded } from "./utils";

export enum EnvironmentStatus {
  PENDING = "pending",
  ACTIVE = "active",
  PAUSED = "paused",
  FATAL_ERROR = "fatal_error",
}

export enum EnvironmentType {
  Test = "test",
  Development = "development",
  Production = "production",
}

/**
 * The slug of any environment. Is a string, but we use this extra little type to distinguish it from any old string.
 **/
export type EnvironmentSlug = Branded<string, "EnvironmentSlug">;

/**
 * The name of a legacy environment.
 * Is either "Development" or "Production". We use a branded type to distinguish these names from other random strings
 * @deprecated -- environments are going to have many different slugs in the future, everything needs to start accepting arbitrary slugs.
 * @see AnyEnvironmentSlug
 **/
export type LegacyEnvironmentName = typeof DevelopmentEnvironmentName | typeof ProductionEnvironmentName;

export const DevelopmentEnvironmentSlug = "development" as Branded<"development", "EnvironmentSlug">;
export const ProductionEnvironmentSlug = "production" as Branded<"production", "EnvironmentSlug">;

export const DevelopmentEnvironmentName = "Development" as Branded<"Development", "EnvironmentName">;
export const ProductionEnvironmentName = "Production" as Branded<"Production", "EnvironmentName">;

export const EnvironmentNames = [DevelopmentEnvironmentName, ProductionEnvironmentName] as const;
export const isEnvironmentName = (environmentName: string): environmentName is LegacyEnvironmentName =>
  (EnvironmentNames as readonly string[]).includes(environmentName);

export const EnvironmentNameMSTType = types.optional(types.frozen<LegacyEnvironmentName>(), DevelopmentEnvironmentName);
export const EnvironmentTypeMSTType = types.optional(
  types.enumeration<EnvironmentType>(Object.values(EnvironmentType)),
  EnvironmentType.Development
);

export const legacyEnvironmentNameMatchesType = (envName: LegacyEnvironmentName, environmentType: EnvironmentType) => {
  return (
    (envName === DevelopmentEnvironmentName && environmentType === EnvironmentType.Development) ||
    (envName === ProductionEnvironmentName && environmentType === EnvironmentType.Production)
  );
};

export const legacyEnvironmentNameToEnvironmentType = (envName: LegacyEnvironmentName): EnvironmentType => {
  if (envName === DevelopmentEnvironmentName) {
    return EnvironmentType.Development;
  } else if (envName === ProductionEnvironmentName) {
    return EnvironmentType.Production;
  } else {
    throw new Error(`Unknown environment name ${envName}`);
  }
};

export const environmentTypeToLegacyEnvironmentName = (environmentType: EnvironmentType): LegacyEnvironmentName => {
  if (environmentType === EnvironmentType.Development) {
    return DevelopmentEnvironmentName;
  } else if (environmentType === EnvironmentType.Production) {
    return ProductionEnvironmentName;
  } else {
    throw new Error(`Unknown environment type ${environmentType}`);
  }
};

export const legacyEnvironmentNameToEnvironmentSlug = (envName: LegacyEnvironmentName): EnvironmentSlug => {
  if (envName === DevelopmentEnvironmentName) {
    return DevelopmentEnvironmentSlug;
  } else if (envName === ProductionEnvironmentName) {
    return ProductionEnvironmentSlug;
  } else {
    throw new Error(`Unknown environment name ${envName}`);
  }
};

export const environmentTypeToNodeEnv = (environmentType: EnvironmentType): string => {
  switch (environmentType) {
    case EnvironmentType.Test:
      return "test";
    case EnvironmentType.Development:
      return "development";
    case EnvironmentType.Production:
      return "production";
  }
};
